export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['Profile']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'All Profiles',
        to: '/profiles',
        icon: 'cil-list-rich'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Add Profile',
        to: '/profiles/add',
        icon: 'cil-library-add'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Broadcast']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Send Broadcast',
        to: '/broadcast/send',
        icon: 'cil-paper-plane'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Broadcast History',
        to: '/broadcast/history',
        icon: 'cil-layers'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['SMS Template']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'All SMS Templates',
        to: '/smstemplates',
        icon: 'cil-list-rich'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Add Template',
        to: '/smstemplates/add',
        icon: 'cil-library-add'
      },
      
      {
        _name: 'CSidebarNavTitle',
        _children: ['Extras']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Inbox',
        to: '/conversation/inbox',
        icon: 'cil-inbox'
      },
    ]
  }
]