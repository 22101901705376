<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <h3 class="c-sidebar-brand-full">
        <img src="/img/logo.png"/>
      </h3>
      <h3 class="c-sidebar-brand-minimized">SB</h3>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

const logo = process.env.NODE_ENV === "production" ? process.env.VUE_APP_PUBLIC_PATH + "/img/logo.png" : "/img/logo.png";

export default {
  name: 'TheSidebar',
  nav,
  data () {
    return {
      logo
    };
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
