<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <avatar
          :username="selfInfo.name"
          :size="40">
        </avatar>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem :href="profileUrl">
      <CIcon name="cil-user" /> {{ selfInfo.name }}
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-briefcase" /> {{ selfInfo.business }}
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem
      @click="logout"
      >
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import Vue from 'vue'
import Avatar from 'vue-avatar'
import AxiosBase from '../api/AxiosBase'

export default {
  name: 'TheHeaderDropdownAccnt',
  created() {
    Vue.component('avatar', Avatar);
    this.getSelfInfo();
  },
  data () {
    return {
      selfInfo: {
        name: localStorage.getItem('username'),
      },
      profileUrl: process.env.VUE_APP_PROFILE_URL
    }
  },
  methods: {
    logout() {
      this.$router.push('/logout');
    },
    getSelfInfo() {
      let _this = this;
      AxiosBase.get("/auth/info", {
        params: { }
      })
      .catch(function(error) {
        console.log(error);
      })
      .then(function(res) {
        if (res?.data) {
          _this.selfInfo = res?.data;
        }
      });
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>